import React from 'react'
import config from '../../config'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
export default function MessageBoard({personData}) {
    const [messageJsonData, setMessageJsonData] = useState([]);

   
    async function fetchMessageInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/4');
            if (responsePage.status === 200) {
                setMessageJsonData(responsePage.data.data.data);
                
            }
            else {
                console.error('Unexpected status code:', responsePage.status);
            }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchMessageInfo();
    }, []);
  return (
    <div>
        <section className='message-board-area'>
            <div className='container'>
                <div className='section-header text-center'>
                    <h3>{personData.title}</h3>
                </div>
                <div className='message-area'> 
                    <div className='row'>
                        <div className='col-md-8 mx-auto'>
                            <div className='row'>
                                {personData.length > 0 && personData.map(item => (
                                    <div className='col-6 text-center' key={item.id}>
                                        <img src={config.FILE_URL + item.thumb} alt='' className='img-fluid'/> 
                                        <p className='person-identity pt-3'>{item.name}</p> 
                                        <p className='person-identity '>{item.designation}</p> 
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: personData.description && (personData.description.length > 500 ? personData.description.substring(0, 500) + '...' : personData.description) }}></p>
                          <div><Link to='/message' className='btn btn-more'>See More</Link></div> 
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
