import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';
export default function Organization() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [aboutPageJsonData, setAboutPageJsonData] = useState([]); 
    const [companywidgetsJsonData, setcompanyWidgetsJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/7');
            if(responsePage.status === 200){
                setAboutPageJsonData(responsePage.data.data);
                if (responsePage.data.data.widgets) {
                    setcompanyWidgetsJsonData(responsePage.data.data.widgets);
                    console.log(companywidgetsJsonData[0])
                } else {
                    console.error('Widgets data not found in the response.');
                }
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
    }, []);
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + aboutPageJsonData.banner_img} alt='' className='img-fluid'/> 
                        <div className='overlay'>
                            <h4>{aboutPageJsonData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{aboutPageJsonData.title}</Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            <section className='py-2 about-license'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                        <div className="about-page-image">
                            <>
                                        {companywidgetsJsonData[0] && companywidgetsJsonData[0].title && <h3>{companywidgetsJsonData[0].title}</h3>}
                                        {companywidgetsJsonData[0] && companywidgetsJsonData[0].content && <div dangerouslySetInnerHTML={{ __html: companywidgetsJsonData[0].content }}></div>}
                                    </>  
                                   <>
                                        {companywidgetsJsonData[1] && companywidgetsJsonData[1].title && <h3>{companywidgetsJsonData[1].title}</h3>}
                                        {companywidgetsJsonData[1] && companywidgetsJsonData[1].content && <div dangerouslySetInnerHTML={{ __html: companywidgetsJsonData[1].content }}></div>}
                                    </> 
                        </div>
                        </div>
                        <div className='col-md-8'>
                            <img src={config.FILE_URL + aboutPageJsonData.thumb} alt='' className='img-fluid'/>
                        </div>
                    </div>
                </div>
            </section>          
        </div>
    </>
  )
}
