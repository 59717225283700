import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Reports =  ({reportData}) => {

  return (
    <section className='py-4'>
        <div className='container'>
            <div className='row'>
                { reportData.length > 0 && reportData.map(item => ( 
               <div className='col-md-3 col-6' key={item.id}>
                    <div className='text-center mt-5 mb-3'>
                        <img src={config.FILE_URL + item.thumb} alt='' className="img img-fluid"/>
                    </div>
                    <h6 className='text-center'><strong>{item.title}</strong></h6>
                </div>
                ))}
            </div>
        </div>
    </section>
  )
}

export default Reports;

