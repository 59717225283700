import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import { SiteInfoContext } from '../SiteInfoContext';
import axios from 'axios';

const Footer = () =>{
    const siteInfo = useContext(SiteInfoContext);
    const [siteSocialJsonData, setSocialJsonData] = useState([]);
    async function fetchSocialInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.SOCIAL_URL);
            // const response = await axios.get('http://localhost:8000/api/Social-info/1');
            setSocialJsonData(response.data.data);
            // console.log('siteInfoJsonData', response.data.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
      }
      useEffect(() => {
        // Make your API call here to fetch the siteInfoJsonData
        fetchSocialInfo();
      }, [0]);
    return(
      <div>
        <div className='text-end'>
            <img src={config.FILE_URL+"images/top-page/footer/bg2.png"} alt="" className='img-fluid' />
        </div>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-4 d-sm-block d-none">
                        <div className="footer-about">
                            <div className="footer-logo">
                                <img src={config.FILE_URL + siteInfo.logo_footer} alt="" className="img-fluid rounded" style={{width: '100px'}}/> 
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                {/* <img src={config.FILE_URL+"images/top-page/footer/location.png"} alt="" className="img-fluid" /> */}
                                <i className="fa-solid fa-location-dot"></i>
                                </div>
                                <p>{siteInfo.address}</p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <i className="fa-solid fa-phone"></i>
                                </div>
                                <p>
                                {siteInfo.phone}
                                </p>                            
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <i className="fa-solid fa-envelope"></i>
                                </div>
                                <p>
                                {siteInfo.email}
                                </p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <i className="fa-solid fa-globe"></i>
                                </div>
                                <p>
                                {siteInfo.web_url}
                                </p>
                            </div>
                            <div className="social-link d-flex flex-wrap">
                            {siteSocialJsonData.length > 0 && siteSocialJsonData.map((item) => (
                                item.status === "1" && (
                                    <a
                                        key={item.id}
                                        href={item.link_url.startsWith('https://') ? item.link_url : 'https://' + item.link_url}
                                        target={item.target}
                                        rel="noreferrer"
                                        className={item.id == 1 ? 'facebook' : item.id == 2 ? 'twitter' : item.id === 3 ? 'youtube' : ''}
                                    >
                                        <i dangerouslySetInnerHTML={{ __html: item.icon }}></i>
                                    </a>
                                )
                            ))}
                                {/* <a href="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></a>
                                <a href="https://twitter.com/phicsart27" rel="noreferrer"  target="_blank" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                                <a href="https://www.youtube.com/channel/UCDgpBnVbbksnr5HslMfT1cQ" rel="noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-youtube"></i></a> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-8'>
                        <div className='footer-link-area'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <ul className="footer-link">
                                        <li><Link to="/about">About us</Link></li>
                                        <li><Link to="/resume">Upload Resume</Link></li>
                                        <li><Link to="/recruitmentprocedure">Requirment Procedure</Link></li>
                                        <li><Link to="/country">Countrys</Link></li>
                                        <li><Link to="/ceo">CEO Speech</Link></li>
                                    </ul>
                                </div>
                                <div className='col-md-4'>
                                    <ul className="footer-link">
                                        <li><Link to="/concern">Our Concerns</Link></li>
                                        <li><Link to="./news">News</Link></li>
                                        <li><Link to="./trainingcenter">Training Center</Link></li>
                                        {/* <li><Link to="./">Privacy Policy</Link></li> */}
                                    </ul>
                                </div>
                                <div className='col-md-4'>
                                    <ul className="footer-link">
                                        <li><Link to="./clients">Our Client</Link></li>
                                        <li><Link to="./contact">Contact Us</Link></li>
                                        <li><Link to="./infogallery">Info Gallery</Link></li>
                                    </ul>
                                </div>                                
                            </div>
                            <div className='footer-calendar '>
                                <h6 className='text-white py-3 fw-bold'>Opening Hours</h6>
                                <p className='text-white mb-1'>Satarday To Thursday 9.00 AM - 5.00 PM</p>
                                <p className='text-white mb-1'>Friday - <span className='text-danger'> Closed</span> </p>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col-md-6 col-lg-4 d-sm-none d-block">
                        <div className="footer-about">
                            <div className="footer-logo">
                                <img src={config.FILE_URL + siteInfo.logo_footer} alt="" className="img-fluid" /> 
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                {/* <img src={config.FILE_URL+"images/top-page/footer/location.png"} alt="" className="img-fluid" /> */}
                                <i className="fa-solid fa-location-dot"></i>
                                </div>
                                <p>{siteInfo.address}</p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <i className="fa-solid fa-phone"></i>
                                </div>
                                <p>
                                {siteInfo.phone}
                                </p>                            
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <i className="fa-solid fa-envelope"></i>
                                </div>
                                <p>
                                {siteInfo.email}
                                </p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <i className="fa-solid fa-globe"></i>
                                </div>
                                <p>
                                {siteInfo.web_url}
                                </p>
                            </div>
                            <div className="social-link d-flex flex-wrap">
                            {siteSocialJsonData.length > 0 && siteSocialJsonData.map((item) => (
                                item.status === "1" && (
                                    <a
                                        key={item.id}
                                        href={item.link_url.startsWith('https://') ? item.link_url : 'https://' + item.link_url}
                                        target={item.target}
                                        rel="noreferrer"
                                        className={item.id == 1 ? 'facebook' : item.id == 2 ? 'twitter' : item.id === 3 ? 'youtube' : ''}
                                    >
                                        <i dangerouslySetInnerHTML={{ __html: item.icon }}></i>
                                    </a>
                                )
                            ))}
                                {/* <a href="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></a>
                                <a href="https://twitter.com/phicsart27" rel="noreferrer"  target="_blank" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                                <a href="https://www.youtube.com/channel/UCDgpBnVbbksnr5HslMfT1cQ" rel="noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-youtube"></i></a> */}
                            </div>
                        </div>                        
                    </div>
                    <p className="text-sm-end text-center text-white pt-3"> Copyright <i className="fa-solid fa-copyright"></i> 2023  {siteInfo.title} |  All Rights Reserved</p>   
                </div>
            </div>
        </footer>      
      </div>
    )
}
export default Footer;
