import React from 'react';

import axios from 'axios';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import About from './Components/About';
import Ceo from './Components/Ceo';
import Clients from './Components/Clients';
import CompnayProfile from './Components/CompnayProfile';
import Concern from './Components/Concern';
import ContactPage from './Components/ContactPage/ContactPage';
import Country from './Components/Country';
import Footer from './Components/Footer';
import Header from './Components/Header';
import HomePage from './Components/HomePage/HomePage';
import HumanResources from './Components/HumanResources';
import InfoGallery from './Components/InfoGallery/InfoGallery';
import Message from './Components/Message';
import News from './Components/News/News';
import NotFound from './Components/NoPage';
import Organization from './Components/Organization';
import Recognition from './Components/Recognition';
import RecruitmentProcedure from './Components/RecruitmentProcedure';
import Resume from './Components/Resume';
import TrainingCenter from './Components/TrainingCenter';
import { SiteInfoProvider } from './SiteInfoContext';
import config from './config';

import { useEffect, useState } from 'react';

function App() {

  // calling page api 
  // const [pageArrayBySlug, setpageArrayBySlug] = useState([]);
  // async function fetchSiteInfo() {
  //   try {
  //     const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/1');
  //     console.log('_pageArrayBySlug', pageArrayBySlug);
  //       if(responsePage.status === 200){
  //           setpageArrayBySlug(responsePage.data.data);
  //         }
  //         else {
  //             console.error('Unexpected status code:', responsePage.status);
  //     }      
  //   } catch (error) {
  //       console.error('Error reading JSON file:', error);
  //   }
  // }

  // useEffect(() => {
  //   fetchSiteInfo();
  // }, []);

  return (
    <div>
    <SiteInfoProvider>
      <Router>
          <Header />
          <Routes>
            {/* <Route path="/:slug?" element={<HomePage />} exact />
            {slug === 'about' ? <Route path="/:slug?" element={<About />} /> : null} */}
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/ceo" element={<Ceo />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/recognition" element={<Recognition />} />
            <Route path="/country" element={<Country />} />
            <Route path="/recruitmentprocedure" element={<RecruitmentProcedure />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/infogallery" element={<InfoGallery />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/news/:id?" element={<News />} />
            <Route path="/company" element={<CompnayProfile />} />
            <Route path="/trainingcenter" element={<TrainingCenter />} />
            <Route path="/humanresources" element={<HumanResources />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/message" element={<Message />} />
            {/* <Route path="/concern" element={<Concern />} /> */}
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/page3" exact component={Page3} /> */}
            {/* Add more routes for your other pages */}
          </Routes>
          <Footer />
      </Router>
    </SiteInfoProvider>
    </div>
  );
}

export default App;
