import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import {Link} from 'react-router-dom';

function Resume() {
    const location = useLocation();
    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);   
    const [resumeJsonData, setResumeJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/17');
            if(responsePage.status === 200){
                setResumeJsonData(responsePage.data.data);
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => { 
        fetchSiteInfo();
    }, []); 
    const initialFormData = {
        name: '',
        passport_no: '',
        passport_issue_date: '',
        passport_expire_date: '',
        age: '',
        qualification_status: '',
        qualification_description: '',
        work_experience_status: '',
        work_experience_description: '',
        experience_duration: '',
        district: '',
        marital_status: '',
        nationality: '',
        phone: '',
        country: '',
        email: '',
        cv_file: null,
      };
    
      const [formData, setFormData] = useState(initialFormData);
      const [successMessage, setSuccessMessage] = useState(null);
      const [loading, setLoading] = useState(false);
    

      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          // Make POST request to the API          
          const response = await axios.post(config.API_URL_LIVE + config.RESUME_URL, formData);          
        //   alert('Form data has been submitted successfully!');
        if(response.status === 201) {
            setSuccessMessage('Your Resume/CV Submitted Successfully');
            setFormData(initialFormData);
            setLoading(false);
            // window.location = '/';
        }          
        } catch (error) {
          console.error('Error submitting form data:', error);
          setLoading(false);
        }
      };
    
      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // if (e.target.type === 'file') {
        //   setFormData({ ...formData, cv_file: e.target.files[0] }); // Update file state
        // } else {
        //     setFormData({ ...formData, [e.target.name]: e.target.value });
        // }
      };
      const handleFileChange = (e) => {
        setFormData({ ...formData, cv: e.target.files[0] }); // Store selected file in formData state
      };
    return(
        <div>
            <section className="contact-section">
                <div className="container">
                <div className='section-page-banner'>
                    <img src={config.FILE_URL + resumeJsonData.thumb} className='img-fluid' alt=''/>
                    <div className='overlay'>
                        <h4>{resumeJsonData.title}</h4>
                        <div className='d-flex text-white align-items-center'>
                            <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                            &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{resumeJsonData.title}</Link>
                        </div>
                    </div>
                </div>
                {/* <div className='section-page-banner'>
                    <img src={config.FILE_URL + resumeJsonData.thumb} className='img-fluid' alt=''/>
                </div> */}
                    <div className="contact-area">
                        <div className="section-header">
                            <h3>Your CV</h3>
                        </div>
                        {/* Success message */}
                        {successMessage && (
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                    {successMessage}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                    </button>
                                </div>
                                )}   
                        <div className="contact-form">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">                                    
                                    <input type="text" className="form-control" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required/>
                                </div>
                                <div className="mb-3">                                     
                                    <input type="text" className="form-control" name="passport_no" placeholder="Passport Number"value={formData.passport_no} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <p className='mb-0'>Passport Issue Date</p> 
                                    <input type="date" className="form-control" name="passport_issue_date" placeholder="Passport Issue Date" value={formData.passport_issue_date} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <p className='mb-0'>Passport Expire Date</p> 
                                    <input type="date" className="form-control" name="passport_expire_date" placeholder="Passport Expire Date" value={formData.passport_expire_date} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <input type="number" className="form-control" name="age" placeholder="Age" value={formData.age} onChange={handleInputChange} required/>
                                </div>
                                <div className="mb-3">
                                   <h6>Qualification</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="qualification_status" value="No" checked={formData.qualification_status === "No"} onChange={handleInputChange}  id="flexRadioDefault1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            No
                                        </label>
                                    </div>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name="qualification_status"  value="YES" checked={formData.qualification_status === "YES"} onChange={handleInputChange}  id="flexRadioDefault2"  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Yes
                                        </label>
                                    </div>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" name='qualification_description' value={formData.qualification_description} onChange={handleInputChange}  rows="5" placeholder="About your qualification"></textarea>
                                </div>
                                <div className="mb-3">
                                    <h6>Work Experience </h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="work_experience_status" id="flexRadioDefault3" value="No" checked={formData.work_experience_status === "No"} onChange={handleInputChange}  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                            No
                                        </label>
                                    </div>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name="work_experience_status" id="flexRadioDefault4" value="YES" checked={formData.work_experience_status === "YES"} onChange={handleInputChange}  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                            Yes
                                        </label>
                                    </div>
                                    <textarea className="form-control" id="exampleFormControlTextarea2" name='work_experience_description' value={formData.work_experience_description} onChange={handleInputChange}  rows="5" placeholder="About your Experience"></textarea>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="experience_duration" placeholder="Experience Year/Month" value={formData.experience_duration} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="district" placeholder="District" value={formData.district} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="marital_status" placeholder="Marital status" value={formData.marital_status} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="nationality" placeholder="Nationality" value={formData.nationality} onChange={handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="phone" placeholder="Phone" value={formData.phone} onChange={handleInputChange} required/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control" name="email" placeholder="email" value={formData.email} onChange={handleInputChange} required/>
                                </div>
                                <div className="mb-3">
                                <select className="form-select" aria-label="Default select example" name="country" value={formData.country} onChange={handleInputChange} required >
                                    <option value="0" selected>Select your country</option>
                                    <option value="UAE">UAE</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Three">Three</option>
                                </select>
                                </div>
                                <div className="mb-3">
                                    <h6>Upload Your CV</h6>
                                    <input className="form-control" type="file" id="formFile" accept=".pdf" name="cv_file" onChange={handleInputChange} required />
                                    <small><strong>Only pdf</strong></small>
                                </div>
                                <button className="btn btn-submit" type="submit" disabled={loading}>
                                    {loading && (
                                        <div className="spinner-border text-success spinner-border-sm me-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                    Send CV
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div> 
    )
}
export default Resume;