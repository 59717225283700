import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';
const WellcomeSection = () =>{
    const [shortInfoJasonData, setShortInfoJasonData] = useState([]);

    async function fetchShortInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL);
            if(response.status === 200){
                setShortInfoJasonData(response.data.data[0]);
           }
           else {
               console.error('Unexpected status code:', response.status);
           }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchShortInfo();
      }, []);
    return(
        <>
            <section className="wellcome-section">
                    <div className="container">
                        
                        <div className="row">
                            <div className="col-md-6">
                                <img src={config.FILE_URL+ shortInfoJasonData.thumb} alt="" className="img-fluid" />
                            </div>
                            <div className="col-md-6">
                                <div className="section-header">
                                    <h1  dangerouslySetInnerHTML={{ __html: shortInfoJasonData.title }}></h1>
                                </div>
                                <div className="short-details">
                                    <p dangerouslySetInnerHTML={{ __html: shortInfoJasonData.description }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
        
    )
}
export default WellcomeSection;