import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';
export default function Message() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [aboutPageJsonData, setAboutPageJsonData] = useState([]); 
    const [messageJsonData, setMessageJsonData] = useState([]);
    const [companywidgetsJsonData, setcompanyWidgetsJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/5');
            if(responsePage.status === 200){
                setAboutPageJsonData(responsePage.data.data);
                if (responsePage.data.data.widgets) {
                    setcompanyWidgetsJsonData(responsePage.data.data.widgets);
                    console.log(companywidgetsJsonData[0])
                } else {
                    console.error('Widgets data not found in the response.');
                }
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    async function fetchMessageInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PERSON_URL);
            if(response.status === 200){
            setMessageJsonData(response.data.data);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
        fetchMessageInfo();
        
    }, []);
    const messageData = messageJsonData.filter(item => item.designation !== 'CEO');
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + aboutPageJsonData.thumb} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{aboutPageJsonData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>Message</Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                    <div className='col-md-4'>
                    <div className="about-page-image">
                        {companywidgetsJsonData.map(item =>{
                            <div key={item.id}>
                                <h3>{item.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: companywidgetsJsonData.content }}></div>
                            </div>
                            
                        })}
                                   
                                   <>
                                        {companywidgetsJsonData[0] && companywidgetsJsonData[0].title && <h3>{companywidgetsJsonData[0].title}</h3>}
                                        {companywidgetsJsonData[0] && companywidgetsJsonData[0].content && <div dangerouslySetInnerHTML={{ __html: companywidgetsJsonData[0].content }}></div>}
                                    </>  
                                   <>
                                        {companywidgetsJsonData[1] && companywidgetsJsonData[1].title && <h3>{companywidgetsJsonData[1].title}</h3>}
                                        {companywidgetsJsonData[1] && companywidgetsJsonData[1].content && <div dangerouslySetInnerHTML={{ __html: companywidgetsJsonData[1].content }}></div>}
                                    </>  
                    </div>
                    </div>
                        <div className="col-md-8">
                            <div className="message-details-area">
                                <div className='d-flex flex-wrap flex-sm-nowrap'>
                                    {messageData.length > 0 && messageData.map(item => (
                                        <div className='w-100 text-center' key={item.id}>
                                            <img src={config.FILE_URL + item.thumb} alt='' className='img-fluid p-4'/> 
                                            <p className='person-identity pt-3'>{item.name}</p> 
                                            <p className='person-identity'> {item.designation}</p> 
                                        </div>
                                    ))}
                                </div>                           
                                <div dangerouslySetInnerHTML={{ __html: aboutPageJsonData.description }}></div>
                                <div className='d-flex flex-wrap flex-sm-nowrap justify-content-between'>
                                {messageData.map((item, index) => (
                                    <div className={`w-100 ${index === 1 ? 'text-end' : ''}`} key={item.id}>
                                        <img src={config.FILE_URL + item.signature_thumb} alt='' className='img-fluid' />
                                        <p className='pt-3'>{item.name}</p>
                                        <p><strong> {item.designation}</strong></p>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-page-image">
                                <img src={config.FILE_URL + aboutPageJsonData.banner_url} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </div>
    </>
  )
}
