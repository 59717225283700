import React, { useEffect, useState }  from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper/modules';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
import axios from 'axios';
// import { Link } from 'react-router-dom'; 
import config from '../../config';
const TopBanner = () =>{
    const [sliderJsonData, setSliderJsonData] = useState([]);

    async function fetchSliderData() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.SLIDER_URL);
            if(response.status === 200){
                 setSliderJsonData(response.data.data);
            }
            else {
                console.error('Unexpected status code:', response.status);
            }
            
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }// siteinfo function 
    useEffect(() => {  
        fetchSliderData();
      }, []);
    return(
        <>
            <section className='slider-section mt-3'>
                <div className='slider-body'>
                    <div className='container'>
                        <div className="slider-area">
                            <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                            >    
                            {sliderJsonData.length > 0 && sliderJsonData.map(item => (
                                <SwiperSlide className="swiper-slide" key={item.id}>
                                    <img src={config.FILE_URL + item.thumb} alt={item.title} className="img"/>
                                </SwiperSlide>
                            ))}                                        
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default TopBanner;