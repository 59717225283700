import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../config';
import { SiteInfoContext } from '../SiteInfoContext';
// import TranslationComponent from './TranslationComponent';
function Header(){ 
    const siteInfo = useContext(SiteInfoContext);
    let location = useLocation();
    return(
        <>
            <div className='top-bar'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 col-6'>
                            <div className='top-bar-left'>
                                <div className='d-flex'>
                                    <Link  to={config.BASE_URL}>
                                            <img src={config.FILE_URL + siteInfo.logo_header} alt="" className="img-fluid" />
                                    </Link> 
                                </div>
                            </div>  
                        </div>
                        <div className='col-md-6 col-6'>
                            <div className='top-bar-right d-flex justify-content-end align-items-center h-100'>                                
                                <a href={`tel:${siteInfo.phone && siteInfo.phone.substring(0, 16)}`}>
                                    <i className="fa-solid fa-phone-volume"></i><span>
                                    {siteInfo.phone && siteInfo.phone.substring(0, 16)}
                                </span></a> 
                                <a href={`mailto:${siteInfo.email}`}> 
                                    <i className="fa-solid fa-envelope-open"></i> 
                                    <span>{siteInfo.email}</span> 
                                </a>
                                {/* <TranslationComponent /> */}
                            </div>
                        </div>
                                
                    </div>
                </div>
            </div>
            <header className='header header-menu' id="header">
                <div className='container'>
                <nav className="navbar navbar-expand-xl bg-body-tertiary">
                    <div className="container p-0">
                        <div></div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav w-100 justify-content-between">
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="./">Home</Link>
                        </li>
                                <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Who Are We
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className={`dropdown-item ${location.pathname === '/company' ? 'active' : ''}`} to="./company">Company Profile</Link></li>
                                    <li><Link className={`dropdown-item ${location.pathname === '/message' ? 'active' : ''}`} to="./message">Message</Link></li>
                                    <li><Link className={`dropdown-item ${location.pathname === '/organization' ? 'active' : ''}`} to="./organization">Organizational Chart</Link></li>
                                </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/recruitmentprocedure' ? 'active' : ''}`} to="./recruitmentprocedure">Recruitment Procedure</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/humanresources' ? 'active' : ''}`} to="./humanresources">Human Resources</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/clients' ? 'active' : ''}`} to="./clients">Our Clients</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/recognition' ? 'active' : ''}`} to="./recognition">Recognition</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/concern' ? 'active' : ''}`} to="./concern">Our Concerns</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/trainingcenter' ? 'active' : ''}`} to="./trainingcenter">Training Center</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/infogallery' ? 'active' : ''}`} to="./infogallery">Info Gallery</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/news' ? 'active' : ''}`} to="./news">News</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} to="./contact">Contact Us</Link>
                                </li>
                        </ul>
                        </div>
                    </div>
                    </nav>
                </div>
            </header>
        </>
      
    ) 
}
export default Header;