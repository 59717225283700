import React, { useEffect, useState }  from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Isotope from './IsotopeDemo';
import config from '../../config';
import { Link } from 'react-router-dom';
  const News = () =>{
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [aboutPageJsonData, setAboutPageJsonData] = useState([]); 
    const [aboutPageWidgetJsonData, setInfoWidgetJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/14');
            if(response.status === 200){
                setAboutPageJsonData(response.data.data);                
                setInfoWidgetJsonData(response.data.data.sections[0].widgets);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
    }, []);
      
    return(
        <div>
            <div className='container'>
            <div className='section-page-banner mt-4'>
                        <img src={config.FILE_URL + aboutPageJsonData.thumb} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{aboutPageJsonData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{aboutPageJsonData.title}</Link>
                            </div>
                        </div>
                    </div>
                <div className='row'>
                    <div className='col-md-8'>                         
                        <Isotope />
                    </div>                    
                    <div className='col-md-4'>
                        <div className="about-page-image">
                            <>
                                {aboutPageWidgetJsonData[0] && aboutPageWidgetJsonData[0].title && <h3>{aboutPageWidgetJsonData[0].title}</h3>}
                                {aboutPageWidgetJsonData[0] && aboutPageWidgetJsonData[0].thumb && <img src={config.FILE_URL + aboutPageWidgetJsonData[0].thumb} alt='n/a' className="img img-fluid" />}
                                {aboutPageWidgetJsonData[0] && aboutPageWidgetJsonData[0].content && <div className='py-3' dangerouslySetInnerHTML={{ __html: aboutPageWidgetJsonData[0].content }}></div>}
                            </> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
export default News;