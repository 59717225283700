import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import { Link } from 'react-router-dom';
export default function Recognition() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [aboutPageJsonData, setAboutPageJsonData] = useState([]); 
    const [companywidgetsJsonData, setcompanyWidgetsJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/11');
            if(responsePage.status === 200){
                setAboutPageJsonData(responsePage.data.data);
                if (responsePage.data.data.sections[0].widgets) {
                    setcompanyWidgetsJsonData(responsePage.data.data.sections[0].widgets);
                    console.log(companywidgetsJsonData)
                } else {
                    console.error('Widgets data not found in the response.');
                }
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    } 
    useEffect(() => {
        fetchSiteInfo();
    }, []);
    const companySidebar = companywidgetsJsonData.filter(item => item.location === 'sidebar');
    const companyJsonData = companywidgetsJsonData.filter(item => item.location === 'recognition');
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + aboutPageJsonData.thumb} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{aboutPageJsonData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{aboutPageJsonData.title}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                        {companyJsonData && companyJsonData && companyJsonData.length > 0 && (
                            <div>
                                <div className='row'>
                                    {companyJsonData.map((item, index) => (
                                        <div className={`col-md-6 mt-4 ${index === companyJsonData.length - 1 ? 'col-md-12 mx-auto' : ''}`} key={item.id}>
                                            <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                                <img src={config.FILE_URL + item.thumb} alt="" className="img-fluid" />
                                            </SlideshowLightbox>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        </div>
                        <div className="col-md-4">
                            <div className="about-page-image">
                            <>
                                        {companySidebar[0] && companySidebar[0].title && <h3>{companySidebar[0].title}</h3>}
                                        {companySidebar[0] && companySidebar[0].content && <div dangerouslySetInnerHTML={{ __html: companySidebar[0].content }}></div>}
                                    </>  
                                   <>
                                        {companySidebar[1] && companySidebar[1].title && <h3>{companySidebar[1].title}</h3>}
                                        {companySidebar[1] && companySidebar[1].content && <div dangerouslySetInnerHTML={{ __html: companySidebar[1].content }}></div>}
                                    </> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>       
        </div>
    </>
  )
}
