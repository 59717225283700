import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
export default function Clients() {
    const location = useLocation(); 

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [clientJsonData, setclientJsonData] = useState([]); 
    const [clientServiceJsonData, setclientServiceJsonData] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationPage, setPaginationPage] = useState([]);
    const [companyJsonData, setcompanyJsonData] = useState([]);
    async function fetchSiteInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/10');
            if(responsePage.status === 200){
                setclientJsonData(responsePage.data.data);
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    async function fetchClientInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.CLIENT_URL);
            if(responsePage.status === 200){
                setclientServiceJsonData(responsePage.data.data.data);
                setPaginationPage(responsePage.data.data);
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    async function fetchCompanySliderData() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.COMPANY_URL);
            if(response.status === 200){
              setcompanyJsonData(response.data.data);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
        fetchClientInfo();
        fetchCompanySliderData();
    }, []);
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + clientJsonData.thumb} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{clientJsonData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{clientJsonData.title}</Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            <section className='py-2 -license'>
                <div className='container'>
                    {clientServiceJsonData && clientServiceJsonData.length > 0 && (
                        <div className='row'>
                            {clientServiceJsonData.length > 0 && clientServiceJsonData.map(item => (
                                <div className='col-md-4 mt-4' key={item.id}>
                                    <div className='client-card h-100'>
                                        <Link to={'https://' + item.link_url} target='_blank'><img src={config.FILE_URL + item.thumb} alt="" className="img-fluid" style={{height: '170px'}} /></Link>
                                        <Link to={'https://' + item.link_url} target='_blank'><h5>{item.title}</h5></Link>
                                        <div className='d-flex'><span><img src={'https://aaatradeinternational.com/uploads/' + 'images/client/contract.png'} alt='0' className='img-fluid' /> </span><p dangerouslySetInnerHTML={{ __html: item.description }}></p></div>
                                        <div className='d-flex'><span><img src={'https://aaatradeinternational.com/uploads/' + 'images/client/period.png'} alt='' className='img-fluid' /> </span><p dangerouslySetInnerHTML={{ __html: item.duration }}></p></div>
                                        <div className='d-flex'><span><img src={'https://aaatradeinternational.com/uploads/' + 'images/client/location.png'} alt='' className='img-fluid' /> </span><p dangerouslySetInnerHTML={{ __html: item.location }}></p></div>
                                        <div className='d-flex'><span><img src={'https://aaatradeinternational.com/uploads/' + 'images/client/no_of_workers.png'} alt='' className='img-fluid' /> </span><p dangerouslySetInnerHTML={{ __html: item.no_of_workers }}></p></div>
                                    </div>
                                </div>
                            ))}
                        </div> 
                    )}
                    <nav aria-label="..." className='pt-4'>
                        {/* <ul class="pagination justify-content-center">
                            <li class="page-item">
                            <span class="page-link">Previous</span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active" aria-current="page">
                            <span class="page-link">2</span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                            </li>
                        </ul> */}
                        {/* <Pagination /> */}
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                            </li>
                            {paginationPage && paginationPage.links && paginationPage.links.map((link, index) => (
                                <li key={index} className={`page-item ${link.active ? 'active' : ''}` + (link.url ? '' : 'd-none')} aria-current="page">
                                    <button className="page-link" onClick={() => setCurrentPage(index + 1)}>{link.label}</button>
                                </li>
                            ))}
                            <li className={`page-item ${!paginationPage.links || !paginationPage.links[2].url ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </section> 
            <div className='pb-5'>
            <section className='company-slider-section'>
                <div className='slider-body'>
                    <div className='container'>
                        <div className='section-header text-center'>
                            <h3>Our Clients</h3>
                        </div>
                        <div className="slider-area">
                            <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 4,
                                  spaceBetween: 50,
                                },
                              }}
                            loop={true}
                            modules={[Autoplay]}
                            className="swiper mySwiper"
                            >    
                            {companyJsonData.map(item => (
                                <SwiperSlide className="swiper-slide" key={item.id}>
                                    <img src={config.FILE_URL + item.thumb} alt={item.name} className="img img-fluid"/>
                                </SwiperSlide>
                            ))}                                        
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            </div>       
        </div>
    </>
  )
}
