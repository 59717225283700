import React from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
// import { Link } from 'react-router-dom'; 
import config from '../../config';
const CompanySlider = ({companyJsonData}) =>{
    return(
        <>
            <section className='company-slider-section'>
                <div className='slider-body'>
                    <div className='container'>
                        <div className='section-header text-center'>
                            <h3>Our Clients</h3>
                        </div>
                        <div className="slider-area">
                            <Swiper
                            spaceBetween={15}
                            slidesPerView={2}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 3,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 4,
                                  spaceBetween: 50,
                                },
                              }}
                            loop={true}
                            modules={[Autoplay]}
                            className="swiper mySwiper"
                            >    
                            {companyJsonData.map(item => (
                                <SwiperSlide className="swiper-slide" key={item.id}>
                                    <img src={config.FILE_URL + item.thumb} alt={item.name} className="img img-fluid"/>
                                </SwiperSlide>
                            ))}                                        
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CompanySlider;

