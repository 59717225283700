import React from 'react';
import config from '../../config';
import axios from 'axios';
import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom'; 

const ConsernSection =  () => {
//   const [concernJsonData, setconcernJsonData] = useState([]);

//   async function fetchSliderData() {
//     try {
//         const responseAbout = await axios.get(config.API_URL_LIVE + config.CONCERN_URL);
//             if(responseAbout.status === 200){
//                 setconcernJsonData(responseAbout.data.data);
//             }
//             else {
//                 console.error('Unexpected status code:', responseAbout.status);
//             } 
//     } catch (error) {
//         console.error('Error reading JSON file:', error);
//     } 
//   }// siteinfo function 
//   useEffect(() => {  
//         console.log('concernJsonData213213', concernJsonData);
//         fetchSliderData();
//     }, []);
  return (
    <section className='py-5 concern-section'>
        <div className='container'>
            {/* <div className='section-header text-center'>
                <h3>Our Concerns</h3> 
            </div>
            <div className='row'>
                {concernJsonData.length > 0  && concernJsonData.map(item => (
                    <div className='col-md-4' key={item.id}>
                        <div className='text-center concern-card my-3'>
                            <img src={config.FILE_URL + item.thumb} alt='' className="img img-fluid"/>
                            <h6 className='text-start'><strong>{item.title}</strong></h6>
                        </div>
                    </div>
                ))}  
            </div> */}
            <img src={config.BASE_URL + 'uploads/images/map/map.svg'} alt='' className='img img-fluid my-5 py-5' />
        </div>
    </section>
  )
}

export default ConsernSection;

