import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';
import MyCalendar from './InfoGallery/MyCalendar';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
export default function TrainingCenter() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [resumePageJsonData, setResumePageJsonData] = useState([]); 
    const [trainingwidgetJsonData, setTrainingWidgetJsonData] = useState([]); 
    const [trainingPageJsonData, setTrainingPageJsonData] = useState([]); 
    async function fetchSidebarInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/12');
            if(response.status === 200){
                setResumePageJsonData(response.data.sections.widgets);
                setTrainingWidgetJsonData(response.status);
                console.log('trainingwidgetJsonData', resumePageJsonData);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    async function fetchTrainingInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.TRAINING_URL);
            if(response.status === 200){
                setTrainingPageJsonData(response.data.data);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSidebarInfo();
        fetchTrainingInfo();
        console.log('trainingPageJsonData', trainingwidgetJsonData);
    }, []);
    const companySidebar = resumePageJsonData.filter(item => item.title !== 'Calendar');
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + trainingwidgetJsonData.thumb} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{trainingwidgetJsonData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{trainingwidgetJsonData.title}</Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="about-page-image">
                                <div className='row'>
                                    {trainingPageJsonData.length > 0 && trainingPageJsonData?.map((item) => (
                                        <div className='col-md-4' key={item.id}>
                                            <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                                <img src={config.FILE_URL + item.thumb} alt='' className='img-fluid py-3' />
                                            </SlideshowLightbox>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-page-image">
                            <>
                                        {companySidebar[0] && companySidebar[0].title && <h3>{companySidebar[0].title}</h3>}
                                        {companySidebar[0] && companySidebar[0].content && <div dangerouslySetInnerHTML={{ __html: companySidebar[0].content }}></div>}
                                    </>  
                                <MyCalendar />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>   
        </div>
    </>
  )
}
