import React, { useEffect, useState }  from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import axios from 'axios';
// import { Link } from 'react-router-dom'; 
import config from '../../config';
import { Link } from 'react-router-dom';
const CountrySlider = ({countryJsonData}) =>{   
    
    return(
        <>
            <section className='country-slider-section'>
                <div className='slider-body'>
                    <div className='container'>
                        <div className='section-header text-center'>
                            <h3 className='text-white mb-4'>We Export Manpower To</h3>
                        </div>
                        <div className="slider-area">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={2}                            
                            loop={true}
                            speed={500}
                            autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 3,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 5,
                                  spaceBetween: 50,
                                },
                              }}
                            modules={[Autoplay]}
                            className="swiper mySwiper"
                            >   
                            {Array.isArray(countryJsonData) && countryJsonData.map(item => (
                                <SwiperSlide className="swiper-slide" key={item.id}>
                                    <img src={config.FILE_URL + item.thumb} alt={item.name} className="img img-fluid"/>
                                    <h6 className='text-center'>{item.title}</h6>
                                </SwiperSlide>
                            ))}                           
                            </Swiper> 
                            <div> 
                            </div>
                        </div>
                        <div className='text-center mt-4'><Link to='/country' className="btn btn-theme2"> View All</Link></div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CountrySlider;

