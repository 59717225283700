import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';
export default function Country() {
    const location = useLocation();
      // Scroll to the top of the page when the route changes
      //const [showModal, setShowModal] = useState(false);
      const [modalContent, setModalContent] = useState('');
      const [modalTitle, setModalTitle] = useState('');
    
      const handleModalOpen = (description, title) => {
        setModalContent(description,);
        setModalTitle(title);
        //setShowModal(true);
      };
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]); 
    const [countryData, setCountryData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/15');
            if(response.status === 200){
                setCountryData(response.data.data);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    const [countryJsonData, setCountryJsonData] = useState([]);

    async function fetchCountryData() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.COUNTRY_URL);
            if(response.status === 200){
                setCountryJsonData(response.data.data.data);
           }
           else {
               console.error('Unexpected status code:', response.status);
           }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
         fetchCountryData();
    }, []);
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + countryData.thumb} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{countryData.title}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{countryData.title}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="row">
                                {countryJsonData.length > 0  && countryJsonData.map((item) => (
                                    <div className="col-md-4" key={item.id}>
                                        <div className="country-card">
                                        <img
                                            src={config.FILE_URL + item.thumb}
                                            alt={item.altText}
                                            className="img-fluid py-3"
                                        />
                                        <h5 className=' fw-bold'>{item.title}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: item.description }} className='px-0'></p>
                                        <button
                                            onClick={() => handleModalOpen(item.description, item.title)}
                                            className="btn btn-modal"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                        >
                                            See More
                                        </button>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">&nbsp;</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5> <strong> Clients In:</strong> {modalTitle}</h5>
                                <p dangerouslySetInnerHTML={{ __html: modalContent }} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="..." className='pt-4'>
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <span className="page-link">Previous</span>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item active" aria-current="page">
                            <span className="page-link">2</span>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </section>   
        </div>
    </>
  )
}
