import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
export default function Ceo() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [CeoJsonData, setCeoJsonData] = useState([]); 
    const [ceowidgetJsonData, setCeoWidgetJsonData] = useState([]); 
    const [personJsonData, setpersonJsonData] = useState([]); 
    async function fetchCeoInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/16');
            if(responsePage.status === 200){
                setCeoJsonData(responsePage.data.data);
                if (responsePage.data.data.widgets) {
                    setCeoWidgetJsonData(responsePage.data.data.widgets);
                } else {
                    console.error('Widgets data not found in the response.');
                }
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    async function fetchPersonData() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PERSON_URL);
            if(response.status === 200){
              setpersonJsonData(response.data.data);
         }
         else {
             console.error('Unexpected status code:', response.status);
         }          
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchCeoInfo();
        fetchPersonData();
    }, []);
    const personData = personJsonData.filter(item => item.designation === 'CEO');
    console.log(personData)
  return (
    <> 
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    {personData.length > 0 && personData.map(item => (
                        <div className='section-ceo-page-banner text-center' key={item.id}>
                            <img src={config.FILE_URL + item.thumb} alt='' className='img-fluid'/>
                                <div className="section-header">                     
                                    <h3 className='text-center'>{item.designation}</h3>  
                                    <h3 className='text-center'>Biography</h3>                   
                                </div>
                        </div>
                    ))}
                    
                    <div className="row">
                        <div className="col-md-12">       
                            <div dangerouslySetInnerHTML={{ __html: CeoJsonData.description }}></div>
                        </div>
                        {personData.length > 0 && personData.map(item => (  
                            <div className='col-md-2 py-5' key={item.id}>
                                <img src={config.FILE_URL + item.signature_thumb} alt="" className="img-fluid ceo-signature-img"/>
                                <h6 className='pt-4'><strong>{item.name}</strong></h6>
                            </div>
                         ))}
                        
                    </div>
                </div>
            </section>
            <section className='py-5 about-license'>
                <div className='container'>
                        <div>
                            <div className='section-header py-3'>
                                {ceowidgetJsonData[0] && ceowidgetJsonData[0].title && <h3 className='text-center'>{ceowidgetJsonData[0].title}</h3>}
                                {ceowidgetJsonData[0] && ceowidgetJsonData[0].content && <p dangerouslySetInnerHTML={{ __html: ceowidgetJsonData[0].content }}></p>}
                            </div>
                        </div>
                    <>
                        
                    </> 
                </div>
            </section>          
        </div>
    </>
  )
}
