import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from './Slider';
import WellcomeSection from './Wellcome';
import CountrySlider from './CountrySlider';
import CompanySlider from './CompnaySlider';
import Reports from './Reports';
import ConsernSection from './ConcernSection';
import MessageBoard from './MessageBoard';
import axios from 'axios';
import config from '../../config';
import { useParams } from 'react-router-dom';

// import FaqArea from './FaqArea'
function HomePage() {
  const location = useLocation();
  const [reportJsonData, setreportJsonData] = useState([]);
  const [companyJsonData, setcompanyJsonData] = useState([]);
  const [personJsonData, setpersonJsonData] = useState([]);
const [countryJsonData, setCountryJsonData] = useState([]);
    
  async function fetchSliderData() {
    // const slug_name  = useParams();

    // console('_params', slug_name);
      try {
          const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL);
          if(response.status === 200){
            setreportJsonData(response.data.data);
       }
       else {
           console.error('Unexpected status code:', response.status);
       }          
      } catch (error) {
          console.error('Error reading JSON file:', error);
      }
  }// siteinfo function 
  async function fetchCountryData() {
      try {
          const response = await axios.get(config.API_URL_LIVE + config.COUNTRY_URL);
          if(response.status === 200){
            // setCountryJsonData(response.data.data.data);
            // console.log('companyJsonData', companyJsonData);
            // Multiply the data by 10
            const countryData = response.data.data.data;
            // Create an array to hold the multiplied data
            let multipliedData = [];

            // Repeat the entire array of data 10 times
            for (let i = 0; i < 10; i++) {
              multipliedData = multipliedData.concat(countryData);
            }

            // Set the state with the multiplied data
            setCountryJsonData(multipliedData);
       }
       else {
           console.error('Unexpected status code:', response.status);
       }          
      } catch (error) {
          console.error('Error reading JSON file:', error);
      }
  }// siteinfo function 
  async function fetchCompanySliderData() {
      try {
          const response = await axios.get(config.API_URL_LIVE + config.COMPANY_URL);
          if(response.status === 200){
            setcompanyJsonData(response.data.data);
            // console.log('companyJsonData', companyJsonData);
       }
       else {
           console.error('Unexpected status code:', response.status);
       }          
      } catch (error) {
          console.error('Error reading JSON file:', error);
      }
  }// siteinfo function 
  async function fetchPersonData() {
      try {
          const response = await axios.get(config.API_URL_LIVE + config.PERSON_URL );
          if(response.status === 200){
            setpersonJsonData(response.data.data);
       }
       else {
           console.error('Unexpected status code:', response.status);
       }          
      } catch (error) {
          console.error('Error reading JSON file:', error);
      }
  }// siteinfo function 
  useEffect(() => {  
      fetchSliderData();
      fetchCompanySliderData(); 
      fetchPersonData();
      fetchCountryData();
    }, []);
 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
  const reportData = reportJsonData.filter(item => item.location === 'report');
  const concernData = reportJsonData.filter(item => item.location === 'homepage-concern');
  const personData = personJsonData.filter(item => item.designation !== 'CEO');
  // console.log('personData', reportJsonData);

// Set the state with the multiplied data
    return(
        <div>
            <Slider/>
            <WellcomeSection />
            {countryJsonData.length > 0 ? <CountrySlider countryJsonData= {countryJsonData}/>: null}            
            {reportData.length > 0 ?<Reports reportData={reportData} />  : null}          
            {companyJsonData.length > 0 ? <CompanySlider companyJsonData= {companyJsonData}/>: null}
            {personData.length > 0 ? <MessageBoard personData= {personData}/>: null}
            <ConsernSection />   
            {/* <FaqArea /> */}
        </div>
    )
}
export default HomePage;