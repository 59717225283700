import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './customCalendar.css'; // Import your custom CSS file
import axios from 'axios';
import config from '../../config';
const MyCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [eventData, setEventData] = useState({});
  async function fetchCalenderInfo() {
    try {
      const responseAbout = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/12');
      if (responseAbout.status === 200) {
        const filteredData = responseAbout.data.sections.widgets.filter(item => item.title === 'Calendar');
        const transformedData = filteredData.reduce((acc, curr) => {
          acc[curr.event_date] = curr.event_title;
          return acc;
        }, {});
        setEventData(transformedData);
      } else {
        console.error('Unexpected status code:', responseAbout.status);
      }
    } catch (error) {
      console.error('Error reading JSON file:', error);
    }
  }  
  useEffect(() => {
    fetchCalenderInfo();
  }, []);
  
  return (
    <div className='calendar-area'>
      <h3>Calendar</h3>
      <Calendar
        value={selectedDate}
        onChange={setSelectedDate}
        tileContent={({ date, view }) =>
          view === 'month' && eventData[date.toISOString().split('T')[0]] && (
            <p>{eventData[date.toISOString().split('T')[0]]}</p>
          )
        }
      />
    </div>
  );
};

export default MyCalendar;
