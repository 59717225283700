import React, { useEffect, useState, useContext }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { SiteInfoContext } from '../../SiteInfoContext';
function ContactPage() {
    const location = useLocation();
    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [contactPageJsonData, setContactPageJsonData] = useState([]); 
    const [showMap] = useState(true); // You can set this state based on your conditions
    // siteinfo function 
    const siteInfo = useContext(SiteInfoContext);
    console.log('siteInfo', siteInfo.map_html);
    async function fetchContactPage() {

        try {
            const responseContact = await axios.get(config.API_URL + config.PAGES_URL);
            // console.log('response__', responseContact.data[0]);
            setContactPageJsonData(responseContact.data[0]);
        } catch (error) {
            // console.log(setShowMap)
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchContactPage();
    }, []);   
    // contact form dynamic function
    
        const [formData, setFormData] = useState({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: '',
          status:0
        });
        const [successMessage, setSuccessMessage] = useState(null);
        const [loading, setLoading] = useState(false);
        const handleInputChange = (e) => {
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value });
        };
      
        const handleSubmit = async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            // Make POST request to the API
            const response = await axios.post(config.API_URL + config.CONTACT_URL, formData);
            console.log(response.data); // Log API response data
            // Reset form after successful submission
            setFormData({
              name: '',
              email: '',
              subject: '',
              phone: '',
              message: '',
              status:0
            });
            // You can add further actions after successful submission
            if (response.status === 201) {
                // Set success message
                setSuccessMessage('Your Message Sent Successfully');
                setLoading(false);
              }
          } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false);
            // Handle error, show error message to the user, etc.
          }
        };
    return(
        <div>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-about">
                                <div className="footer-logo">
                                    <img src={config.FILE_URL + siteInfo.logo_header} alt="" className="img-fluid" /> 
                                </div>
                                <div className="footer-location d-flex">
                                    <div className="me-3">
                                    {/* <img src={config.FILE_URL+"images/top-page/footer/location.png"} alt="" className="img-fluid" /> */}
                                    <i className="fa-solid fa-location-dot"></i>
                                    </div>
                                    <p>{siteInfo.address}</p>
                                </div>
                                <div className="footer-location d-flex">
                                    <div className="me-3">
                                    <i className="fa-solid fa-phone"></i>
                                    </div>
                                    <p>
                                    {siteInfo.phone}
                                    </p>                            
                                </div>
                                <div className="footer-location d-flex">
                                    <div className="me-3">
                                    <i className="fa-solid fa-envelope"></i>
                                    </div>
                                    <p>
                                    {siteInfo.email}
                                    </p>
                                </div>
                                <div className="footer-location d-flex">
                                    <div className="me-3">
                                    <i className="fa-solid fa-globe"></i>
                                    </div>
                                    <p>
                                    {siteInfo.web_url}
                                    </p>
                                </div>
                                <div className="social-link d-flex flex-wrap">
                                    <a href="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></a>
                                    <a href="https://twitter.com/phicsart27" rel="noreferrer"  target="_blank" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                                    <a href="https://www.youtube.com/channel/UCDgpBnVbbksnr5HslMfT1cQ" rel="noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-youtube"></i></a>
                                </div>
                            </div>            
                        </div>
                        <div className="col-md-6">
                            <div className="contact-area">
                                <div className="section-header">
                                    <h3>{contactPageJsonData.title}</h3>
                                </div>
                                {/* Success message */}
                                {successMessage && (
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                    {successMessage}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                    </button>
                                </div>
                                )}                               
                                <div className="contact-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            placeholder="Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        </div>
                                        <div className="mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        </div>
                                        <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            placeholder="Subject"
                                            value={formData.subject}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        </div>
                                        <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            placeholder="Phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        </div>
                                        <div className="mb-3">
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            rows="5"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            required
                                        ></textarea>
                                        </div>
                                        <button className="btn btn-submit" type="submit" disabled={loading}>
                                            {loading && (
                                                <div className="spinner-border text-success spinner-border-sm me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                                </div>
                                            )}
                                            Send Message                                             
                                        </button>
                                    </form>
                                    </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                {showMap && (
            // <iframe
            //     title="my-iframe-title"
            //     src={contactPageJsonData.googleMapUrl}
            //     width="100%"
            //     height="650"
            //     style={{ border: '0' }}
            //     allowFullScreen=""
            //     loading="lazy"
            //     referrerPolicy="no-referrer-when-downgrade"
            // ></iframe>
            // eslint-disable-next-line jsx-a11y/iframe-has-title
            // <iframe
            //     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.5127573767386!2d90.41962747602254!3d23.729087789592917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b845322e1fed%3A0x93864ffeb0e250e9!2z4Kau4KeH4Ka44Ka-4Kaw4KeN4Ka4IOCmuOCmvuCmsuCmvuCmriDgpp_gp43gprDgp4fgpqHgpr_gpoIg4KaH4Kao4KeN4Kaf4Ka-4Kaw4Kao4KeN4Kav4Ka-4Ka24Kao4Ka-4Kay!5e0!3m2!1sen!2sbd!4v1702243809492!5m2!1sen!2sbd"
            //     style={{ width: '100%', height: '500px'}}
            //     allowfullscreen=""
            //     loading="lazy"
            //     referrerpolicy="no-referrer-when-downgrade"
            // ></iframe>
            <iframe srcdoc={siteInfo.map_html} frameborder="0" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            )}
            </div>
            </div>
        </div> 
    )
}
export default ContactPage;