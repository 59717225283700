import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
export default function About() {
    const location = useLocation();
    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [aboutPageJsonData, setAboutPageJsonData] = useState([]); 
    const [aboutPageLIcenseJsonData, setAboutLicenseJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.PAGES_URL + '/1');
            if(responsePage.status === 200){
                setAboutPageJsonData(responsePage.data.data);
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
   
    async function fetchLicenseInfo() {
        try {
            const responsePage = await axios.get(config.API_URL_LIVE + config.CERTIFICATION_URL);
            if(responsePage.status === 200){
                setAboutLicenseJsonData(responsePage.data.data);
             }
             else {
                 console.error('Unexpected status code:', responsePage.status);
             }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
        fetchLicenseInfo();
    }, []);
  return (
    <>
        <div className='about-page'>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className='section-page-banner'>
                        <img src={config.FILE_URL + aboutPageJsonData.banner_img} alt='' className='img-fluid'/>
                        <div className='overlay'>
                            <h4>{aboutPageJsonData.name}</h4>
                            <div className='d-flex text-white align-items-center'>
                                <Link to='../'><i className="fa-solid fa-house"></i> Home </Link>
                                &nbsp; &nbsp;<i className="fa-solid fa-chevron-right"></i>  &nbsp; &nbsp;<Link to='.'>{aboutPageJsonData.name}</Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-header">
                                <h3>{aboutPageJsonData.name}</h3>                            
                                <div dangerouslySetInnerHTML={{ __html: aboutPageJsonData.description }}></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-page-image">
                                <img src={config.FILE_URL + aboutPageJsonData.thumb} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='py-5 about-license'>
                <div className='container'>
                    {aboutPageLIcenseJsonData && aboutPageLIcenseJsonData.length > 0 && (
                        <div>
                            <div className='section-header text-center py-3'>
                                <h3>{aboutPageLIcenseJsonData.title}</h3>
                            </div>
                            <div className='row'>
                                {Array.isArray(aboutPageLIcenseJsonData) && aboutPageLIcenseJsonData.map(item => (
                                    <div className='col-md-4 mt-4' key={item.id}>
                                        <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                                <img src={config.FILE_URL + item.thumb} alt="" className="img-fluid " />
                                        </SlideshowLightbox>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    )}
                </div>
            </section>    
        </div>
    </>
  )
}
